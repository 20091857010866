import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import { $FixMe } from '@eventbrite/ts-utils';
import React from 'react';
import { connect } from 'react-redux';
import {
    handleApplyFilter as handleApplyFilterAction,
    handleRemoveFilter as handleRemoveFilterAction,
} from '../../../../redux/actions/search';
import './ExpandDedupedEventCTA.scss';

interface ExpandDedupedEventCTAProps {
    handleApplyFilter: (params: { hash: string }) => void;
    dedup: {
        count: number;
        hash?: string;
    };
    eventId: string;
    name: string;
}

const ExpandDedupedEventCTA: React.FunctionComponent<
    ExpandDedupedEventCTAProps
> = (props) => {
    const handleExpandClick = () => {
        props.handleApplyFilter({
            hash: props.dedup.hash || '',
        });
    };

    const resultCount = props.dedup.count;
    const eventName = props.name;
    const copy = gettext('View %(resultCount)s similar results', {
        resultCount,
        eventName,
    });

    return (
        <div className="expand-dedupe-event-cta eds-align--center eds-l-pad-top-2">
            <Button
                style="link"
                onClick={handleExpandClick}
                __containerClassName="eds-l-pad-vert-1"
            >
                {copy}
            </Button>
        </div>
    );
};

const _mapStateToProps = (state: {
    app: {
        experiments: object;
    };
    user: {
        isStaff: boolean;
    };
    admin: {
        experimentOverride: {
            variant: any;
        };
    };
}) => {
    const experiments = state.app.experiments;
    const isStaff = state.user.isStaff;
    const experimentOverride = state.admin.experimentOverride;
    let variant;

    // Assume that if we have dupes then we are in a dupe experiment,
    // since the experiment name will change.
    if (experiments) {
        variant = Object.values(experiments)[0];
    }

    // Admin debug tool
    if (isStaff && experimentOverride) {
        variant = experimentOverride.variant;
    }

    return {
        variant,
    };
};

const _mapDispatchToProps = (dispatch: Function) => ({
    handleApplyFilter: (...args: [$FixMe]) =>
        dispatch(handleApplyFilterAction(...args)),
    handleRemoveFilter: (...args: [$FixMe]) =>
        dispatch(handleRemoveFilterAction(...args)),
});

export default connect(
    _mapStateToProps,
    _mapDispatchToProps,
)(ExpandDedupedEventCTA);
